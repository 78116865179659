<template>
    <modal ref="modalRegistrarPago" titulo="Registrar pago de administración" adicional="Registrar" @adicional="registrarPago">
      <div v-loading="loading">
        <div class="row mx-0" >
            <div class="col-12">
                <p class="f-17 f-500 mb-2 d-middle">
                    <img src="/img/generales/pago_efectivo.svg" alt="" class="pr-2">
                    <span v-if="! _.isEmpty(pago.pago_mes)"> {{ convertMoney(pago.pago_mes.valor) }} </span> 

                </p>
                <span v-if="!_.isUndefined(pago.agrupacion)" class="f-15 f-500 mb-2"> {{pago.agrupacion.nombre}} </span> 
                <span v-if="!_.isUndefined(pago.vivienda)" class="f-15 f-500 mb-2"> {{pago.vivienda.nombre}} </span> 
            </div>
        </div>
        <div class="row mx-0 mt-3">
            <div class="col-12">
                <p class="f-12 pl-2 label-inputs">Comentario</p>
                <el-input type="textarea" :autosize="{ minRows: 4}" placeholder="Déjale un comentario" v-model="comentario" maxlength="300" show-word-limit> </el-input>
            </div>
        </div>
      </div>
        
    </modal>
</template>
<script>
import Viviendas from '~/services/viviendas'
export default {
    data(){
        return{
            comentario:'',
            loading: false,
            pago:{
              id : null,
            }
        }
    },
    created(){
    },
    methods:{
        toggle(id_pago){
            this.$refs.modalRegistrarPago.toggle()
            this.pago.id = id_pago
            this.obtenerPago()
        },
        async obtenerPago(){
          try {
            this.loading = true
            const {data} = await Viviendas.mostrarPago(this.pago.id)
            this.pago = data.data
            this.loading = false
          } catch (e){
              console.log(e)
              this.loading = false
          }          
        },
        async registrarPago(){
          try {
            this.loading = true
            let payload={
              id_pago : this.pago.id,
              comentario_admin : this.comentario
            }
            const {data} = await Viviendas.registarPago(payload)
            if(data.success){
              this.$refs.modalRegistrarPago.toggle()
              this.$notify({
                title: 'Registrar Pago',
                message: data.mensaje,
                type: 'success'
              });

              this.$emit('refresh')

            }
            this.loading = false
          } catch (e){
              console.log(e)
              this.loading = false
              this.$notify({
                title: 'Registrar Pago',
                message: 'Error registrando el pago manul',
                type: 'warning'
              });
          }  
        }
    }
}
</script>